<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x pad-top--xl pad-bottom--vxl">
      <div class="cell small-12">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <h1 class="large-heading">Log out</h1>
            <p class="pad-top">You are now logged out</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
  mounted() {
    this.logout();
  },
};
</script>
