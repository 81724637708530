<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <h1>Login</h1>
          </div>
        </div>
        <div class="grid-x grid-padding-x pad-top--xl">
          <div class="cell small-12 medium-4">
            <label>Email</label>
          </div>
          <div class="cell small-12 medium-8">
            <input
              v-on:focus="clearMessage"
              v-model="user.email_address"
              type="email" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-4">
            <label>Password</label>
          </div>
          <div class="cell small-12 medium-8">
            <div class="password-wrap">
              <input
                v-model="user.password"
                type="password" />
                <span class="show-password">Show</span>
              </div>
          </div>
        </div>
        <div class="grid-x grid-padding-x pad-top">
          <div class="cell small-12 medium-4 medium-offset-8">
            <button
              v-on:click="login"
              class="button large expanded">Log In</button>
          </div>
        </div>
        <div
          v-if="message.length > 0"
          class="grid-x grid-padding-x">
          <div class="cell small-12 medium-8 medium-offset-4">
            <h3 class="text-right">{{ message }}</h3>
          </div>
        </div>
        <div class="grid-x grid-padding-x pad-top--small">
          <div class="cell small-12 medium-4 medium-offset-8">
            <p class="small text-right">
              <router-link
                to="/request-password">
                Forgot password?
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Login',
  data() {
    return {
      user: {
        email_address: '',
        password: '',
      },
      message: '',
    };
  },
  methods: {
    clearMessage() {
      this.message = '';
    },
    login() {
      axios.post('/users/login.json', this.user)
        .then((response) => {
          if (response.data.response === 1) {
            this.message = 'Sorry that email or password was not recognised. Please try again.';
          } else {
            this.$store.dispatch('saveToken', response.data.response);
            if (response.data.response.userlevel === 1) {
              this.$router.push('/home');
            }
            if (response.data.response.userlevel === 2) {
              this.$router.push('/admin');
            }
          }
        });
    },
  },
};
</script>
